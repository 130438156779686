import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// components
import { getAUserAction } from "../../../redux/users/userActions";

import {
  concludeWalletTransactionAction,
  getATransactionAction,
} from "../../../redux/walletTransaction/transactionActions";
import Button from "../../../components/buttons/buttons";

export default function SingleTransaction() {
  let { id } = useParams();
  const dispatch = useDispatch();

  let lastFourMonth = new Date();
  lastFourMonth.setDate(lastFourMonth.getDate() - 120);

  const { transaction, isLoading } = useSelector(
    (state) => state.walletTransaction
  );

  const { singleUser } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (transaction?.reference_id && transaction?.information?.recipient_id) {
      dispatch(getAUserAction(transaction?.information?.recipient_id));
    }
    // eslint-disable-next-line
  }, [transaction?.id]);

  useEffect(() => {
    dispatch(getATransactionAction(id));
    // eslint-disable-next-line
  }, []);

  return (
    <div data-test="singleRestaurant-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">{"WITHDRAWAL DETAILS"}</h3>
          {transaction?.status === "pending" && (
            <Button.Primary
              type={"button"}
              title={"Conclude"}
              loading={isLoading}
              onClick={() =>
                dispatch(
                  concludeWalletTransactionAction({
                    reference_id: transaction?.reference_id,
                    status: "completed",
                  })
                )
              }
            />
          )}
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Transaction ID
            </label>
            <p className="text-[#151515] text-sm">
              {transaction?.reference_id}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Amount</label>
            <p className="text-[#151515] text-sm break-words">
              {transaction?.amount}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Payout Type</label>
            <p className="text-[#151515] text-sm">{transaction?.type}</p>
          </div>

          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(transaction?.created_at).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] text-sm uppercase">
              {transaction?.status}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Type Name</label>
            <p className="text-[#151515] text-sm">
              {transaction?.information?.payout_details?.name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Wallet Address
            </label>
            <p className="text-[#151515] text-sm">
              {transaction?.information?.payout_details?.address}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Recipient Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm">
              {singleUser?.profile?.user_name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm break-words">
              {singleUser?.profile?.user_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">
              {singleUser?.profile?.phone ?? "Not available"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
            <p className="text-[#151515] text-sm">
              {singleUser?.profile?.address ?? "Not available"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
